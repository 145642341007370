import { forwardRef, HTMLProps, ReactNode } from "react";

import Label from "../Label";
import { cn } from "../lib/utils";

interface RadioProps extends Omit<HTMLProps<HTMLInputElement>, "label" | "type" | "size"> {
  label: ReactNode;
  id?: string;
  inline?: boolean;
  name?: string;
  type: "radio" | "checkbox";
  size?: "sm" | "md";
}

const RadioCheckboxBase = forwardRef<
  HTMLInputElement,
  RadioProps & {
    type: "radio" | "checkbox";
  }
>(({ type, label, id, name, inline, size = "md", ...props }, ref) => {
  return (
    <div className={inline ? "inline-flex flex-row items-center mr-5" : ""}>
      <Label className="font-normal">
        <span className="inline-flex flex-row items-center cursor-pointer">
          <input
            ref={ref}
            type={type}
            name={name || id}
            className={type === "radio" ? "form-radio" : "form-checkbox"}
            {...props}
          />
          <span className={cn(size === "md" && "ml-3", size === "sm" && "ml-2 text-sm")}>
            {label}
          </span>
        </span>
      </Label>
    </div>
  );
});

RadioCheckboxBase.displayName = "RadioCheckboxBase";

export default RadioCheckboxBase;
