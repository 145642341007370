import type { Messages } from "@lingui/core";

import { messages as sharedInternalTranslations } from "@kavval/constants/i18n-internal/fr/messages";
import { messages as sharedTranslations } from "@kavval/constants/i18n/fr/messages";
import { messages as cookiesTranslations } from "@kavval/cookieconsent/i18n/fr/messages";
import { messages as uiTranslations } from "@kavval/ui/i18n/fr/messages";

import { messages as bookingMessages } from "../../i18n/fr/messages";

const messages: Messages = {
  ...uiTranslations,
  ...bookingMessages,
  ...sharedInternalTranslations,
  ...sharedTranslations,
  ...cookiesTranslations,
};

export default messages;
