import type { ChangeEventHandler } from "react";
import { useCallback, useEffect, useState, type ComponentProps } from "react";
import { t } from "@lingui/macro";
import EmailValidator from "email-validator";
import { get } from "lodash";
import { useFormContext } from "react-hook-form";
import { useDebounceCallback } from "usehooks-ts";

import { Input } from "@kavval/ui";
import { useConfirmedEmail } from "@kavval/ui/hooks/useValidateEmail";

import { formatError } from "@booking/components/Form/utils";
import { rawTrpc } from "@booking/lib/trpc";

import InputField from "../Input";

const validateEmailSyntax = (value: string) =>
  EmailValidator.validate(value) ||
  t({
    id: "page.details.emailFormatError",
    message: "Cette adresse email n'est pas valide",
  });

const BasicEmailField = ({
  registerOptions,
  ...props
}: Omit<ComponentProps<typeof InputField>, "type">) => {
  return (
    <InputField
      {...props}
      type="email"
      registerOptions={{
        ...registerOptions,
        validate: {
          ...registerOptions?.validate,
          validateEmailSyntax,
        },
      }}
    />
  );
};

// Les erreurs dans react hook form ne peuvent être que des string.
// On stock donc simplement une constante pour identifier une erreur d'API
// et afficher un message d'erreur plus complexe au moment venu (voir plus
// bas sur <InputField />)
const EMAIL_VALIDATION = "EMAIL_VALIDATION";

// HUGE WARNING !
// `rawTrpc.validateEmail.query` n'est pas stable !
// à chaque render on récupère une fonction différente...
// pour tester : `useEffect(() => console.log('changed'), [rawTrpc.validateEmail.query])`
// On stock donc la fonction dans une constante pour éviter ça.
const validateEmailTrpcQuery = rawTrpc.validateEmail.query;

const EmailFieldWithValidationService = ({
  registerOptions,
  ...props
}: Omit<ComponentProps<typeof InputField>, "type">) => {
  const {
    setValue,
    clearErrors,
    trigger,
    register,
    formState: { errors },
  } = useFormContext();
  const error = get(errors, props.name);
  const { validateEmail, error: emailValidationError } = useConfirmedEmail({
    endpoint: validateEmailTrpcQuery,
    onSuggestionClicked: (suggestion) => {
      setValue(props.name, suggestion, { shouldDirty: true, shouldValidate: true });
    },
    onConfirmClicked: (checked) => {
      if (checked) {
        clearErrors(props.name);
      }

      trigger(props.name);
    },
  });

  const checkEmail = useCallback(
    async (value: string) => {
      try {
        return (await validateEmail(value)) ? undefined : false;
      } catch (e) {
        return undefined;
      }
    },
    [validateEmail]
  );

  // Source de l'inspiration pour la validation debounced ET asynchrone :
  // https://blog.benorloff.co/debounce-form-inputs-with-react-hook-form
  const [debouncedEmailValue, setEmailValue] = useState<string>("");
  const setDebouncedEmailValue = useDebounceCallback(setEmailValue, 1000);

  const onChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      const { value } = e.target;
      // On ne veut pas valider tout de suite !!!
      // C'est le useEffect plus bas (déclanché sur la valeur debounced) qui va s'en charger.
      // Si jamais l'utilisateur submit immédiate avant l'exécution useEffect, c'est react-hook-form qui
      // va appeler la validation.
      setValue(props.name, value, { shouldDirty: true, shouldValidate: false });
      setDebouncedEmailValue(value);
    },
    [props.name, setDebouncedEmailValue, setValue]
  );

  // When the debounced urlValue changes, trigger validation
  useEffect(() => {
    trigger(props.name);
  }, [debouncedEmailValue, props.name, trigger]);

  return (
    <>
      <Input
        {...props}
        required={!!registerOptions.required}
        type="email"
        {...register(props.name, {
          validate: {
            ...registerOptions.validate,
            [EMAIL_VALIDATION]: checkEmail,
          },
        })}
        error={
          error?.type === EMAIL_VALIDATION ? (
            <div>{emailValidationError}</div>
          ) : (
            formatError(get(errors, props.name))
          )
        }
        // On override l'onChange pour ne pas déclencher la validation habituelle de react-hook-form
        // (c'est le useEffect plus haut qui va s'en charger)
        onChange={onChange}
      />
    </>
  );
};

const EmailField = ({
  validateAgainstEmailService,
  ...props
}: Omit<ComponentProps<typeof InputField>, "type"> & { validateAgainstEmailService?: boolean }) => {
  return validateAgainstEmailService ? (
    <EmailFieldWithValidationService {...props} />
  ) : (
    <BasicEmailField {...props} />
  );
};

export default EmailField;
