import { useCallback, useMemo, useRef, useState } from "react";

import EmailErrorMessage from "@kavval/ui/EmailErrorMessage";

type ValidateEmailEndpointResult = { suggestion: string | null; status: string | null };
type ValidateEmailEndpointFn = (email: string) => Promise<ValidateEmailEndpointResult>;

// https://github.com/colinhacks/zod/blob/main/src/types.ts#L660
const emailRegex =
  // eslint-disable-next-line no-useless-escape
  /^(?!\.)(?!.*\.\.)([A-Z0-9_'+\-\.]*)[A-Z0-9_+-]@([A-Z0-9][A-Z0-9\-]*\.)+[A-Z]{2,}$/i;

type ValidationResult = { suggestion: string | null; isValid: boolean; email: string };

const requestCache: Record<string, Promise<ValidateEmailEndpointResult>> = {};
// let latestCall: {email: string, ts: number } | undefined;

const callWithCache = (fn: ValidateEmailEndpointFn, email: string) => {
  if (!requestCache[email]) {
    requestCache[email] = fn(email);
  }

  // latestCall = { email, ts: new Date().getTime()}

  return requestCache[email];
};

/**
 * Custom hook to validate and confirm an email address using a provided endpoint.
 *
 * @param {Object} params - The parameters for the hook.
 * @param {(email: string) => ValidateEmailEndpointFn} params.endpoint - The endpoint function to validate the email.
 *
 * @returns {Object} - The hook's return values.
 * @returns {boolean} showEmailConfirmation - State indicating whether to show email confirmation.
 * @returns {ValidateEmailEndpointFn} validateEmail - Function to validate the email.
 * @returns {React.Dispatch<React.SetStateAction<boolean>>} setIsEmailUserConfirmed - Function to set the email user confirmation state.
 * @returns {React.Dispatch<React.SetStateAction<boolean>>} setShowEmailConfirmation - Function to set the show email confirmation state.
 */
export const useConfirmedEmail = ({
  endpoint,
  onSuggestionClicked,
  onConfirmClicked: onConfirmClickedProp,
}: {
  endpoint: ValidateEmailEndpointFn;
  onSuggestionClicked: (suggestion: string) => void;
  onConfirmClicked?: (checked: boolean) => void;
}) => {
  const [isEmailUserConfirmed, setIsEmailUserConfirmed] = useState(false);
  const [isValidating, setValidating] = useState(false);

  const [validationResult, setValidationResult] = useState<ValidationResult | undefined>();
  const previousEmail = useRef<string | undefined>();

  const setValidationResultAndReturn = useCallback((result: ValidationResult) => {
    setValidationResult(result);
    setValidating(false);
    // L'email est considéré valide que si l'API retourne un status "valid" et qu'il n'y a pas de suggestion
    // (s'il y a une suggestion on va demander à l'utilisateur de confirmer l'email)
    return result.isValid && !result.suggestion;
  }, []);

  const validateSyntax = useCallback(
    (email: string) => {
      return setValidationResultAndReturn({
        suggestion: null,
        isValid: emailRegex.test(email),
        email,
      });
    },
    [setValidationResultAndReturn]
  );

  const validateEmail = useCallback(
    async (rawEmail: string) => {
      const email = rawEmail.toLowerCase().trim();

      // On valide l'email avec un regex simple pour ne pas appeler l'API pour rien
      if (!emailRegex.test(email)) {
        return setValidationResultAndReturn({
          suggestion: null,
          isValid: false,
          email,
        });
      }

      // Si l'utilisateur force la validation de l'email, on considère l'adresse comme valide
      // On ne tombe jamais sur ce cas sur le premier call de la fonction, c'est forcément après une première verification
      if (isEmailUserConfirmed && (!previousEmail.current || previousEmail.current === email)) {
        return true;
      }

      previousEmail.current = email;

      setIsEmailUserConfirmed(false);
      setValidating(true);

      try {
        // On test l'email avec le endpoint API en paramètre (TRPC qui retourne une reponse bouncer)
        const { suggestion, status } = await callWithCache(endpoint, email);

        return setValidationResultAndReturn({
          suggestion,
          isValid: status === "valid",
          email,
        });
      } catch (error) {
        // On retourne valide si il y a une erreur dans l'API, on ne veut pas bloquer l'utilisateur
        return setValidationResultAndReturn({
          suggestion: null,
          isValid: true,
          email,
        });
      }
    },
    [isEmailUserConfirmed, setValidationResultAndReturn, endpoint]
  );

  const onConfirmClicked = useCallback(
    (checked: boolean) => {
      setIsEmailUserConfirmed(true);
      // On utilise setTimeout pour que le state ait le temps de se mettre à jour.
      // Sans ça l'appel à onConfirmClickedProp se fait et il redéclenche une validation du formulaire
      // avant que isEmailUserConfirmed soit à jour.
      setTimeout(() => onConfirmClickedProp?.(checked), 0);
    },
    [onConfirmClickedProp]
  );

  const error = useMemo(() => {
    // On affiche le message d'erreur si l'email est valide et qu'il y a une suggestion

    if (validationResult && (!validationResult?.isValid || validationResult?.suggestion)) {
      return (
        <EmailErrorMessage
          showEmailConfirmation={validationResult.isValid && !!validationResult.suggestion}
          onConfirmClicked={onConfirmClicked}
          email={validationResult.email}
          suggestion={validationResult.suggestion}
          onSuggestionClicked={(sugg) => {
            setValidationResult(undefined);
            previousEmail.current = undefined;
            setIsEmailUserConfirmed(false);
            onSuggestionClicked(sugg);
          }}
        />
      );
    }

    return null;
  }, [onConfirmClicked, onSuggestionClicked, validationResult]);

  return {
    error,
    isValidating,
    validateEmail,
    validateSyntax,
    setIsEmailUserConfirmed,
  };
};
