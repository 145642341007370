import { useCallback, useEffect, useMemo, useReducer } from "react";
import { onAuthStateChanged } from "firebase/auth";

import { getAuth } from "@booking/lib/firebase";

// Inspiré de https://github.com/CSFrequency/react-firebase-hooks/tree/master/auth

const defaultState = (defaultValue) => {
  return {
    loading: defaultValue === undefined || defaultValue === null,
    value: defaultValue,
  };
};

const reducer = () => (state, action) => {
  switch (action.type) {
    case "error":
      return {
        ...state,
        error: action.error,
        loading: false,
        value: undefined,
      };
    case "reset":
      return defaultState(action.defaultValue);
    case "value":
      return {
        ...state,
        error: undefined,
        loading: false,
        value: action.value,
      };
    default:
      return state;
  }
};

const useLoadingValue = (getDefaultValue) => {
  const defaultValue = getDefaultValue ? getDefaultValue() : undefined;
  const [state, dispatch] = useReducer(reducer(), defaultState(defaultValue));

  const setError = useCallback((error) => dispatch({ type: "error", error }), [dispatch]);
  const setValue = useCallback((value) => dispatch({ type: "value", value }), [dispatch]);
  const reset = useCallback(
    () =>
      dispatch({ type: "reset", defaultValue: getDefaultValue ? getDefaultValue() : undefined }),
    [dispatch, getDefaultValue]
  );

  return useMemo(
    () => ({
      error: state.error,
      loading: state.loading,
      reset,
      setError,
      setValue,
      value: state.value,
    }),
    [state.error, state.loading, reset, setError, setValue, state.value]
  );
};

const useAuthState = () => {
  const auth = getAuth();
  const { error, loading, setError, setValue, value } = useLoadingValue(() =>
    auth ? auth.currentUser : null
  );

  useEffect(() => {
    if (!auth) return;

    // if (!value && auth.currentUser) {
    //   setValue(value);
    // }

    const unsubscribe = onAuthStateChanged(auth, setValue, setError);

    return () => unsubscribe();
  }, [auth, setError, setValue]);

  return useMemo(() => ({ user: value, loading, error }), [value, loading, error]);
};

export default useAuthState;
