import { forwardRef, useImperativeHandle, useState } from "react";
import type { ForwardRefRenderFunction, PropsWithChildren } from "react";
import ChevronIcon from "@material-symbols/svg-600/rounded/stat_1.svg";

import { cn } from "@kavval/ui";
import { useMediaQuery } from "@kavval/ui/hooks/useMediaQuery";

export type EventAccordionRef = { closeAll: () => void };

type EventAccordionItem = {
  id: string;
  title: (isSelected: boolean) => JSX.Element;
  contentElements: {
    id: string;
    render: (isSelected: boolean) => JSX.Element;
  }[];
};
export type EventAccordionProps = ForwardRefRenderFunction<
  EventAccordionRef,
  {
    onChange?: (id: string) => void;
    isDisabledOnDesktop?: boolean;
    initialIndex?: number;
    items: EventAccordionItem[];
  }
>;

type EventAccordionItemProps = PropsWithChildren<{
  borderless?: boolean;
  isDisabledOnDesktop?: boolean;
  id: string;
  isOpen: boolean;
  onClick: () => void;
  title: EventAccordionItem["title"];
}>;

const EventAccordionItem = ({
  borderless,
  id,
  isOpen,
  onClick,
  title,
  children,
  isDisabledOnDesktop,
}: EventAccordionItemProps) => {
  const isDesktop = useMediaQuery("md", "up");
  const isDisabled = isDesktop && isDisabledOnDesktop;
  const isItemOpen = isOpen || isDisabled;

  const onItemClick = () => {
    if (isDisabled) return;
    onClick();
  };

  return (
    <div
      id={id}
      className={cn(
        "grid overflow-hidden transition-[grid-template-rows] bg-white border-b border-gray-200",
        isOpen ? "grid-rows-[auto_1fr]" : "grid-rows-[auto_0fr]",
        isDisabledOnDesktop && "md:grid-rows-[auto_1fr]",
        borderless && isDisabledOnDesktop && "md:border-b-0",
        !borderless && "md:mb-4"
      )}
    >
      <div
        role="button"
        onClick={onItemClick}
        aria-controls={`${id}-content`}
        className={cn(
          "w-full p-4  relative flex items-center cursor-pointer",
          isDisabledOnDesktop && "md:cursor-default md:p-0"
        )}
        aria-expanded={isItemOpen}
      >
        {title(isItemOpen ?? false)}

        <ChevronIcon
          className={cn(
            "fill-gray-500",
            isItemOpen && "rotate-180",
            isDisabledOnDesktop && "md:hidden"
          )}
        />
      </div>
      <div
        id={`${id}-content`}
        className={cn(
          "min-h-0 transition-[visibility] ",
          isItemOpen ? "visible" : "invisible",
          isDisabledOnDesktop && "md:visible"
        )}
      >
        <div className={cn("p-4", isDisabledOnDesktop && "md:p-0 md:pb-4")}>{children}</div>
      </div>
    </div>
  );
};

const EventAccordion: EventAccordionProps = (
  { initialIndex, items, isDisabledOnDesktop, onChange },
  ref
) => {
  const [selectedIndex, setSelectedIndex] = useState(initialIndex ?? null);

  const onSelect = (id: string, index: number) => {
    const value = selectedIndex === index ? null : index;
    setSelectedIndex(value);
    onChange && onChange(id);
  };

  useImperativeHandle(ref, () => ({
    closeAll: () => {
      setSelectedIndex(null);
    },
  }));
  return (
    <div>
      {items.map(({ title, contentElements, id }, index) => (
        <EventAccordionItem
          isDisabledOnDesktop={isDisabledOnDesktop}
          key={id}
          id={id}
          onClick={() => onSelect(id, index)}
          isOpen={selectedIndex === index}
          title={title}
          borderless={index === items.length - 1}
        >
          {contentElements
            .filter((_, i) => i === 0)
            .map((element) => (
              <div key={element.id}>{element.render(selectedIndex === index)}</div>
            ))}
        </EventAccordionItem>
      ))}
    </div>
  );
};

export default forwardRef(EventAccordion);
