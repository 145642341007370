import type { ComponentPropsWithoutRef } from "react";
import { get } from "lodash";
import { useFormContext } from "react-hook-form";

import { Input } from "@kavval/ui";

import type { CommonFieldProps, ExtraFieldProps } from "../../types";
import { formatError } from "../../utils";

type FormInputProps = ComponentPropsWithoutRef<typeof Input> & CommonFieldProps & ExtraFieldProps;

const InputField = ({ registerOptions, error, ...props }: FormInputProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const { name } = props;
  return (
    <Input
      {...props}
      required={!!registerOptions.required}
      {...register(name, registerOptions)}
      error={error || formatError(get(errors, name))}
    />
  );
};

export default InputField;
