import type { KeyboardEvent, MouseEvent } from "react";
import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const onPress = (callback: (e: MouseEvent | KeyboardEvent) => void) => {
  return {
    onClick: (e: MouseEvent) => callback(e),
    onKeyDown: (e: KeyboardEvent) => e.key === "Enter" && callback(e),
  };
};
