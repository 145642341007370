import type { MouseEventHandler } from "react";
import { useCallback } from "react";
import { Trans } from "@lingui/macro";

import Checkbox from "../Checkbox";

const EmailErrorMessage = ({
  showEmailConfirmation,
  suggestion,
  onSuggestionClicked,
  onConfirmClicked,
  email,
}: {
  showEmailConfirmation: boolean;
  suggestion: string | null | undefined;
  email: string;
  onSuggestionClicked?: (suggestion: string) => void;
  onConfirmClicked?: (value: boolean) => void;
}) => {
  const onClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
    (e) => {
      e.preventDefault();
      if (onSuggestionClicked) {
        onSuggestionClicked(suggestion!);
      }
    },
    [onSuggestionClicked, suggestion]
  );

  return (
    <>
      <div className="text-sm">
        <Trans id="generic.invalidEmail.pleaseEnterValidEmail">
          Cette adresse email ne semble pas valide.
        </Trans>
      </div>
      {suggestion && (
        <div className="mt-1 text-sm">
          <Trans id="generic.invalidEmail.trySuggestionEmail">
            Voulais-tu dire{" "}
            <a href="#" className="lowercase text-electric-blue underline" onClick={onClick}>
              {suggestion}
            </a>{" "}
            ?
          </Trans>
        </div>
      )}
      {showEmailConfirmation && (
        <div className="mt-4">
          <Checkbox
            size="sm"
            onClick={(e) => onConfirmClicked?.(e.currentTarget.checked)}
            label={
              <Trans id="generic.invalidEmail.confirmEmail">
                Je confirme que <span className="underline underline-offset-1">{email}</span> est
                bien mon adresse email.
              </Trans>
            }
          />
        </div>
      )}
    </>
  );
};

export default EmailErrorMessage;
