"use client";

import { useMemo, type FC } from "react";
import type { I18n } from "@lingui/core";
import { useLingui } from "@lingui/react";
import clsx from "clsx";
import { uniq } from "lodash";

import type {
  AlgoliaCategoryVariant,
  EventPreviewData,
  GenericDiscipline,
} from "@kavval/constants";
import { formatGenericDistance, translateDiscipline } from "@kavval/formatters";
import type { Locale } from "@kavval/lingui-config/locales";

import styles from "./styles.module.css";

const replaceThinSpace = (str: string) => str.replace("\u2009", "\u00A0");

type Highlights = { min?: number; max?: number; variant?: AlgoliaCategoryVariant };

interface DistancesProps {
  disciplineVariants: EventPreviewData["raceDisciplineVariants"];
  distanceVariants: EventPreviewData["raceDistanceVariants"];
  unitVariants: EventPreviewData["raceDistanceUnitVariants"];
  categoryVariants: EventPreviewData["raceCategoryVariants"];
  highlights?: Highlights;
  className?: string;
}

const matchHighlight = (d: number, highlights?: Highlights) => {
  if (!highlights || (!highlights.min && !highlights.max)) return false;

  if (highlights.min && highlights.max) {
    return d >= highlights.min && d <= highlights.max;
  }
  if (highlights.min) {
    return d >= highlights.min;
  }
  if (highlights.max) {
    return d <= highlights.max;
  }

  return false;
};

const formatAlgoliaCategoryVariant = (
  variant: AlgoliaCategoryVariant,
  showDiscipline: boolean,
  i18n: I18n
) => {
  const [discipline, category] = variant.split("%") as [GenericDiscipline, string];

  if (/ironman/i.test(category) || !showDiscipline) {
    return category;
  }

  /* lingui-extract-ignore */
  return `${translateDiscipline(i18n, discipline)} ${category}`;
};

const Distances: FC<DistancesProps> = ({
  disciplineVariants,
  distanceVariants,
  unitVariants,
  categoryVariants,
  highlights,
  className,
}) => {
  const { i18n } = useLingui();
  const hasMultipleDisciplines = useMemo(
    () => uniq(disciplineVariants).length > 1,
    [disciplineVariants]
  );

  const items: Array<{ key: string; label: string; match: boolean }> = useMemo(() => {
    const result = distanceVariants.map((distance, index) => {
      const unit = unitVariants[index];
      const discipline = disciplineVariants[index];
      const category = categoryVariants[index];

      let label: string;

      if (category) {
        label = formatAlgoliaCategoryVariant(category, hasMultipleDisciplines, i18n);
      } else {
        label = replaceThinSpace(formatGenericDistance(i18n.locale as Locale, distance, unit));
      }

      return {
        key: `${distance}${unit}${discipline}${category}`,
        discipline,
        distance,
        label: label,
        match: matchHighlight(distance, highlights),
      };
    });

    return result.reduce(
      (acc, item) => {
        if (!acc.some(({ label }) => label === item.label)) {
          acc.push(item);
        }

        return acc;
      },
      [] as typeof result
    );
  }, [
    distanceVariants,
    unitVariants,
    disciplineVariants,
    categoryVariants,
    highlights,
    i18n,
    hasMultipleDisciplines,
  ]);

  return (
    <div className={clsx(styles.Distances, className)}>
      {items.map(({ key, label, match }) => (
        <span className={match ? "font-bold text-orange" : undefined} key={key}>
          {label}
        </span>
      ))}
    </div>
  );
};

export default Distances;
