import { useMediaQuery as useMediaQueryHook } from "usehooks-ts";

import {
  breakpoint2xl,
  breakpointLg,
  breakpointMd,
  breakpointSm,
  breakpointXl,
  breakpointXs,
} from "@kavval/design-tokens";

const breakpoints = {
  xs: breakpointXs,
  sm: breakpointSm,
  md: breakpointMd,
  lg: breakpointLg,
  xl: breakpointXl,
  "2xl": breakpoint2xl,
};

type Breakpoints = keyof typeof breakpoints;

export function useMediaQuery(breakpoint: Breakpoints, direction: "up" | "down" = "up") {
  const mediaQueryValue = breakpoints[breakpoint];

  const mediaQuery =
    direction === "up"
      ? `(min-width: ${mediaQueryValue}px)`
      : `(max-width: ${mediaQueryValue - 1}px)`;

  return useMediaQueryHook(mediaQuery);
}
