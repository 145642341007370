import { i18n } from "@lingui/core";
import { createTRPCProxyClient } from "@trpc/client";
import { createTRPCReact, httpBatchLink, TRPCClientError } from "@trpc/react-query";
import type { inferRouterInputs, inferRouterOutputs } from "@trpc/server";

import type { BookingRouter } from "@api/trpc/bookingProcedures/router";

import { getCurrentUser } from "@booking/lib/firebase";
import { ENV_VARS } from "@booking/utils/env-vars";

const link = httpBatchLink({
  url: `https://api.${ENV_VARS.KAVVAL_DOMAIN}/public/trpc/booking`,
  fetch(url, options) {
    return fetch(url, {
      ...options,
      // Pour passer les cookies "employees" ou "NEXT_LOCALE"
      credentials: "include",
    });
  },
  async headers() {
    const user = await getCurrentUser();
    const jwtToken = await user?.getIdToken();

    return {
      "accept-language": i18n.locale,
      ...(jwtToken
        ? {
            // eslint-disable-next-line
            Authorization: `Bearer ${jwtToken}`,
          }
        : {}),
    };
  },
});

export type Input = inferRouterInputs<BookingRouter>;
export type Output = inferRouterOutputs<BookingRouter>;

export const trpc = createTRPCReact<BookingRouter>();

export const rawTrpc = createTRPCProxyClient<BookingRouter>({
  links: [link],
});

export const isTrpcError = (e: unknown): e is TRPCClientError<BookingRouter> => {
  return e instanceof TRPCClientError;
};

export const getReactTrpcClient = () =>
  trpc.createClient({
    links: [link],
  });
